import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Collection } from "../shared/collection.model";
import { CollectionsService } from "../shared/collections.service";
import { MatTableDataSource, MatPaginator, PageEvent, MatDialog, MatSnackBar } from "@angular/material";
import { Page } from "../shared/page.model";
import { Router } from "@angular/router";
import { CancelYesDialogComponent } from "../../core/cancel-yes-dialog.component";
import { Observable } from "rxjs";

@Component({
    templateUrl: `collectionList.component.html`
})
export class CollectionListComponent implements OnInit, AfterViewInit {

    static URL = 'collectionList';
    private collectionList: Collection[];
    page: number;
    size: number;
    dataSource: MatTableDataSource<Collection>;
    public pageCollections: Page;
    displayedColumns = ['name', 'acctions'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    pageSizeOptions: number[] = [25, 50, 100];
    pageEvent: PageEvent;
    canDeleteItem: boolean;
    constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private collectionService: CollectionsService, private router: Router) {
        this.canDeleteItem = false;
        this.page = 0;
        this.size = 25;
        this.dataSource = new MatTableDataSource<Collection>(this.collectionList);
        this.pageCollections = {
            totalPages: 0,
            content: undefined,
            totalElements: 0,
            last: false,
            size: 0,
            number: 0,
            sort: undefined,
            numberOfElements: 0,
            first: false
        }
    }

    ngOnInit(): void {
        this.SyncData();
    }



    public SyncData(): any {
        this.readCollectionsAllPaginate(this.page, this.pageSizeOptions[0]);
    }

    readCollectionsAllPaginate(page: number, size: number, filter: String = null) {
        this.collectionService.readCollectionsAllPaginate(page, size, filter).subscribe(
            pageCollections => {
                this.pageCollections = pageCollections;
                this.collectionList = this.pageCollections.content;
                this.dataSource = new MatTableDataSource<Collection>(this.collectionList);
                this.paginator = this.dataSource.paginator;
            }
        );
    }

    applyFilter(filterValue: string) {
        this.readCollectionsAllPaginate(this.page, this.pageSizeOptions[0], filterValue)
    }

    nextPage(pageEvent: PageEvent) {
        this.readCollectionsAllPaginate(pageEvent.pageIndex, pageEvent.pageSize);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }


    editCollection(id: string) {
        this.router.navigate(['home/collectionDetails', id]);
    }

    deleteCollection(id: string) {
        this.canDelete(id).subscribe(
            haveBooks => {
                if (!haveBooks) {
                    let dialogRef = this.dialog.open(CancelYesDialogComponent);
                    dialogRef.componentInstance.message = '¿Estas seguro que quieres borrar esta Collectión?';
                    dialogRef.afterClosed().subscribe(
                        result => {
                            if (result) {
                                this.collectionService.deleteCollection(id).subscribe(
                                    data => {
                                        this.SyncData();
                                    }
                                );
                            }
                        }
                    );
                } else {
                    this.mensajes('No se puede borrar esta colección porque tiene libros asociados');
                }
            }
        );
    }
    canDelete(id: string): Observable<boolean> {
        return this.collectionService.haveBooks(id);
    }

    add() {
        this.router.navigate(['home/collectionDetails/new']);
    }

    mensajes(mesage: string): any {
        this.snackBar.open(mesage, '', {
            duration: 2000
        });
    }

}