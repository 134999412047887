import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ParentErrorStateMatcher, PasswordValidator } from '../shared/validators/password.validator';
import { UserValidatorMessages } from '../shared/validators/userValidatorsMessages';

@Component({
    selector: 'change-set-password-dialog',
    template: `
    <h1 mat-dialog-title>Contraseña</h1>
    <form [formGroup]="userPassForm">
    <section formGroupName="matching_passwords" fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="center center">
        <mat-form-field appearance="outline" fxFlex="49" fxFlex.xs="100">
            <mat-label>Contraseña</mat-label>
            <input matInput placeholder="Contraseña" value="" formControlName="password">
            <mat-error *ngFor="let validation of userValidatorMessages.account_validation_messages.password">
                        <mat-error class="error-message" *ngIf="userPassForm.get('matching_passwords').get('password').hasError(validation.type) && (userPassForm.get('matching_passwords').get('password').dirty || userPassForm.get('matching_passwords').get('password').touched)">{{validation.message}}</mat-error>
                    </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="49" fxFlex.xs="100">
            <mat-label>Repetir Contraseña</mat-label>
            <input matInput placeholder="Repetir Contraseña" value="" formControlName="confirm_password" [errorStateMatcher]="parentErrorStateMatcher">
            <mat-error *ngFor="let validation of userValidatorMessages.account_validation_messages.confirm_password">
                        <mat-error class="error-message" *ngIf="(userPassForm.get('matching_passwords').get('confirm_password').hasError(validation.type) || userPassForm.get('matching_passwords').hasError(validation.type)) && (userPassForm.get('matching_passwords').get('confirm_password').dirty || userPassForm.get('matching_passwords').get('confirm_password').touched)">{{validation.message}}</mat-error>
            </mat-error>
        </mat-form-field>
    </section>
    <div mat-dialog-actions>
    <button type="submit" [mat-dialog-close]="matching_passwords_group.controls['password'].value" [disabled]="!userPassForm.valid"  mat-button>Guardar<mat-icon>save</mat-icon></button>
    <button type="button" [mat-dialog-close]="false" mat-button>Cancelar<mat-icon>cancel</mat-icon></button>
    </div>
    </form>`
})
export class ChangeSetPasswordDialogComponent implements OnInit {

    matching_passwords_group: FormGroup;
    parentErrorStateMatcher = new ParentErrorStateMatcher();
    userPassForm: FormGroup;
    userValidatorMessages = new UserValidatorMessages();

    passwordOutput: string

    constructor(private formBuilder: FormBuilder) {
        this.passwordOutput = null;
    }

    ngOnInit(): void {
        this.prepareForm();
    }

    prepareForm(): any {
        this.matching_passwords_group = new FormGroup({
            password: new FormControl('', Validators.compose([
                Validators.minLength(5),
                Validators.required
                // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
            ])),
            confirm_password: new FormControl('', Validators.required)
        }, (formGroup: FormGroup) => {
            return PasswordValidator.areEqual(formGroup);
        });

        this.userPassForm = this.formBuilder.group({
            matching_passwords: this.matching_passwords_group
        });
    }

}