import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CollectionsService } from "../shared/collections.service";
import { Collection } from "../shared/collection.model";
import { UserService } from "../shared/user.service";
import { Page } from "../shared/page.model";
import { Observable, Subject } from 'rxjs';

@Component({
    selector: `collections`,
    styleUrls: [`collections.component.css`],
    templateUrl: `collections.component.html`
})
export class CollectionsComponent implements OnInit {
    public static URL = "collections";
    collections: Collection[];
    pageCollection: Page;
    isCollectionEmpty: boolean;
    pagination: any[];
    userId: string;
    //filterValue = new Subject<string>();
    filterValue = new Subject<string>();
    viewResult: boolean;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private collectionsService: CollectionsService, private userService: UserService) {
        // this.id = activatedRoute.snapshot.params['id'];
        this.isCollectionEmpty = false;
        this.userId = null;
        this.viewResult = null;
    }

    ngOnInit(): void {
        this.pagination = [
            { page: 0 },
            { size: 15 }
        ];

        this.syncData();
    }

    syncData() {
        this.userService.readCurrentUser().subscribe(
            user => {
                this.userId = user.id;
                this.readUserCollections(this.userId, 0, 25);
            }
        );

    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.filterValue.next(filterValue);
            this.viewResult = true;
        } else {
            this.viewResult = false;
        }
    }

    readUserCollections(userId: string, page: number, size: number, filter: string = null): any {
        this.collectionsService.readUserCollections(userId, page, size, filter).subscribe(
            page => {
                this.pageCollection = page;
                this.collections = page.content;
                if (page.totalElements == 0) {
                    this.isCollectionEmpty = true;
                } else {
                    this.isCollectionEmpty = false;
                }
            }
        );
    }

    collectionDetails(collectionId: String) {
        this.router.navigate(['home/collection/', collectionId]);
    }
}