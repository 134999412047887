import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../shared/user.service";
import { BookService } from "../shared/book.service";
import { Collection } from "../shared/collection.model";
import { Book } from "../shared/book.model";
import { CollectionsService } from "../shared/collections.service";
import { Subject } from 'rxjs';

@Component({
    selector: 'collection',
    styleUrls: [`collection.component.css`],
    templateUrl: `collection.component.html`
})
export class CollectionComponent implements OnInit {

    static URL = 'collection/:id';
    collection: Book[];
    collectionItem: Collection;
    book: any;
    id: string;
    userId: string;
    isCollectionBooksEmpty: boolean;
    filterValue = new Subject<string>();
    viewResult: boolean;
    constructor(private userService: UserService, private collectionsService: CollectionsService, private bookService: BookService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.id = activatedRoute.snapshot.params['id'];
    }

    ngOnInit(): void {
        this.userService.readCurrentUser().subscribe(
            user => {
                this.userId = user.id;
                this.readUserbooksInCollection(this.userId, this.id, 0, 25);
            });
        this.collectionsService.readCollection(this.id).subscribe(
            collection => {
                this.collectionItem = collection;
            }
        );

    }

    readUserbooksInCollection(userId: string, collectionid: string, page: number, size: number, filter: string = null): any {
        this.bookService.readUserBooksOfACollection(userId, collectionid, page, size, filter).subscribe(
            books => {
                this.collection = books.content;
                this.isEmpty(books.totalElements);
            }
        );
    }

    isEmpty(totalElements: number): any {
        if (totalElements == 0) {
            this.isCollectionBooksEmpty = true;
        } else {
            this.isCollectionBooksEmpty = false;
        }
    }

    applyFilter(filterValue: string) {
        if (filterValue) {
            this.viewResult = true;
            this.filterValue.next(filterValue);
        } else {
            this.viewResult = false;
        }
    }

    bookDetails(id: number) {
        this.router.navigate(['home/book/', id]);
    }

    addBook() {
        this.collection.push(this.book);
    }

}