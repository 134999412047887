
export class UserValidatorMessages {
    validation_messages = {
        'name': [
            { type: 'required', message: 'EL nombre es requerido' }
        ],
        'birthday': [
            { type: 'required', message: 'Porfavor introduzca su fecha de nacimiento' },
            { type: 'pattern', message: 'Formato de fecha incorrecto' }
        ]
    };
    account_validation_messages = {
        'email': [
            { type: 'required', message: 'El email es requerido' },
            { type: 'pattern', message: 'Email inválido' },
            { type: 'notUnique', message: 'Este email ya está registrado' }
        ],
        'confirm_email': [
            { type: 'required', message: 'Confirme el email' },
            { type: 'areEqual', message: 'Los emails no coinciden' }
        ],
        'confirm_password': [
            { type: 'required', message: 'Confirme la contraseña' },
            { type: 'areEqual', message: 'Las contraseñas no coinciden' }
        ],
        'password': [
            { type: 'required', message: 'La contraseña es requerida' },
            { type: 'minlength', message: 'La contraseña tiene que tener al menos 5 caracteres' },
            { type: 'pattern', message: 'La contraseña tiene que tener al menos una letra mayuscula, una letra miniscula y un numero' }
        ],
        'postalcode': [
            { type: 'required', message: 'Código postal requerido' }
        ],
        'lastname': [
            { type: 'required', message: 'Apellidos requeridos' }
        ],
        'termsaccept': [
            { type: 'pattern', message: 'Acepte los términos y condiciones de uso' }
        ]
    }

    login_validation_messages = {
        'email': [
            { type: 'required', message: 'El email es requerido' },
            { type: 'pattern', message: 'Email invalido' }
        ],
        'password': [
            { type: 'required', message: 'La contraseña es requerida' }
        ]
    }
}