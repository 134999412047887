import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { User } from "../shared/user.model";
import { UserService } from "../shared/user.service";
import { MatTableDataSource, MatPaginator, PageEvent, MatDialog, MatSnackBar } from "@angular/material";
import { Page } from "../shared/page.model";
import { Router } from "@angular/router";
import { CancelYesDialogComponent } from "../../core/cancel-yes-dialog.component";
import { Observable } from "rxjs";

@Component({
    templateUrl: `userList.component.html`
})
export class UserListComponent implements OnInit, AfterViewInit {

    static URL = 'userList';
    private userList: User[];
    page: number;
    size: number;
    dataSource: MatTableDataSource<User>;
    public pageUser: Page;
    displayedColumns = ['id', 'name', 'acctions'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    pageSizeOptions: number[] = [25, 50, 100];
    pageEvent: PageEvent;
    canDeleteItem: boolean;
    constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private userService: UserService, private router: Router) {
        this.canDeleteItem = false;
        this.page = 0;
        this.size = 25;
        this.dataSource = new MatTableDataSource<User>(this.userList);
        this.pageUser = {
            totalPages: 0,
            content: undefined,
            totalElements: 0,
            last: false,
            size: 0,
            number: 0,
            sort: undefined,
            numberOfElements: 0,
            first: false
        }
    }

    ngOnInit(): void {
        this.SyncData();
    }

    public SyncData(): any {
        this.readAllPaginate(this.page, this.pageSizeOptions[0]);
    }

    readAllPaginate(page: number, size: number) {
        this.userService.readAll(page, size).subscribe(
            pageUser => {
                this.pageUser = pageUser;
                this.userList = this.pageUser.content;
                this.dataSource = new MatTableDataSource<User>(this.userList);
                this.paginator = this.dataSource.paginator;
            }
        );
    }

    nextPage(pageEvent: PageEvent) {
        this.readAllPaginate(pageEvent.pageIndex, pageEvent.pageSize);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }


    edit(id: string) {
        this.router.navigate(['home/userDetails', id]);
    }

    delete(id: string) {
        let dialogRef = this.dialog.open(CancelYesDialogComponent);
        dialogRef.componentInstance.message = '¿Estas seguro que quieres borrar este usuario?';
        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                    this.userService.delete(id).subscribe(
                        data => {
                            this.SyncData();
                        }
                    );
                }
            }
        );

    }


    add() {
        this.router.navigate(['home/userDetails/new']);
    }

    mensajes(mesage: string): any {
        this.snackBar.open(mesage, '', {
            duration: 2000
        });
    }

}