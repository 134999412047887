import { Component, OnInit, ViewChild } from "@angular/core";
import { Book } from "../shared/book.model";
import { BookService } from "../shared/book.service";
import { Page } from "../shared/page.model";
import { MatTableDataSource, MatPaginator, MatSnackBar, MatDialog, PageEvent } from "@angular/material";
import { Router } from "@angular/router";
import { CancelYesDialogComponent } from "../../core/cancel-yes-dialog.component";

@Component({
    templateUrl: `bookList.component.html`
})
export class BookListComponent implements OnInit {

    static URL = 'booklist';
    private bookList: Book[];
    private page: number;
    private size: number;
    public pageBooks: Page;

    displayedColumns = ['title', 'collection', 'acctions'];

    dataSource: MatTableDataSource<Book>;
    pageSizeOptions: number[] = [25, 50, 100];
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog, private bookService: BookService, private router: Router, public snackBar: MatSnackBar) {
        this.page = 0;
        this.size = 5;
        this.pageBooks = {
            totalPages: 0,
            content: undefined,
            totalElements: 0,
            last: false,
            size: 0,
            number: 0,
            sort: undefined,
            numberOfElements: 0,
            first: false
        }
        this.dataSource = new MatTableDataSource<Book>(this.bookList);
    }

    ngOnInit(): void {
        this.SyncData();
    }

    public SyncData(): void {
        this.readAllPaginate(this.page, this.pageSizeOptions[0]);
    }

    nextPage(pageEvent: PageEvent) {
        this.readAllPaginate(pageEvent.pageIndex, pageEvent.pageSize);
    }

    readAllPaginate(pageIndex: number, pageSize: number, term: string = null): any {
        this.bookService.readBooksAll(pageIndex, pageSize, term).subscribe(
            pageBooks => {
                this.pageBooks = pageBooks;
                this.bookList = this.pageBooks.content;
                this.dataSource = new MatTableDataSource<Book>(this.bookList);
            }
        );
    }

    editBook(id: string) {
        this.router.navigate(['home/bookDetails', id]);
    }

    addBook() {
        this.router.navigate(['home/bookDetails/new']);
    }

    applyFilter(filterValue: string) {
        this.readAllPaginate(this.page, this.pageSizeOptions[0], filterValue);
    }

    deleteBook(id: string) {
        let dialogRef = this.dialog.open(CancelYesDialogComponent);
        dialogRef.componentInstance.message = '¿Estas seguro que quieres borrar este libro?';
        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                    this.bookService.deleteBook(id).subscribe(
                        data => {
                            this.SyncData();
                            this.mensajes('Libro borrado correctamente');
                        },
                        error => {
                            this.mensajes('Error al borrar el libro ' + error);
                        }
                    );
                }
            });
    }

    mensajes(mesage: string): any {
        this.snackBar.open(mesage, '', {
            duration: 2000
        });
    }
}