import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'front-filter-data',
    template: `
    <section fxLayout="row wrap" fxLayoutAlign="end center" class="container-front-filter-data">
        <mat-form-field *ngIf="isVisible" appearance="outline" fxFlex="70" class="customOutlineInput front-filter-data">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar"> 
        </mat-form-field>
        <button fxFlex="19" mat-button (click)="toggle()">
            <mat-icon *ngIf="!isVisible" matSuffix>search</mat-icon>
        </button>
        <button fxFlex="19" mat-button (click)="close()">
        <mat-icon *ngIf="isVisible" matSuffix>close</mat-icon>
    </button>
    </section>
    `
})
export class FrontFilterDataComponent {
    public isVisible: boolean;
    @Output()
    textOutput: EventEmitter<String> = new EventEmitter<String>();

    applyFilter(event) {
        if (!event) {
            this.textOutput.emit(null);
        } else {
            this.textOutput.emit(event);
        }
    }

    toggle() {
        (this.isVisible) ? this.isVisible = false : this.isVisible = true;
    }
    close() {
        this.isVisible = false;
        this.textOutput.emit(null);
    }

}