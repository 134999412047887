import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material";

import { AngularFireStorage } from '@angular/fire/storage';
import '@angular/fire/storage';
import { finalize } from "rxjs/operators";
import { UploadFileData } from "./uploadFileData.model";
import { firebase } from '@firebase/app';
import { error } from 'protractor';
@Injectable()
export class FirebaseUploadService {
    filePath = 'uploads';
    bookPath = 'books';
    collectionsPath = 'collections';
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
    uploadFileData: UploadFileData;

    constructor(private afStorage: AngularFireStorage, public snackBar: MatSnackBar) {

    }

    deleteFile(url: string): void {
        if (this.parseUrl(url)) {
            let refurl = this.afStorage.storage.refFromURL(url);
            refurl.getDownloadURL().then(function (urli) {

            }).catch(
                function (error) {
                    return;
                }
            );
            this.afStorage.storage.refFromURL(url).delete();
            this.mensajes('Archivo borrado');
        }
    }

    parseUrl(url: string): boolean {
        let result: boolean = false;
        let googleurl: string = 'https://firebasestorage.googleapis.com/v0/';
        if (url.toString().includes(googleurl)) {
            result = true;
        }
        return result;
    }

    uploadFileCollections(file: any, collectionSlug: string): Observable<UploadFileData> {
        return this.uploadFile(file, '/' + this.collectionsPath + '/' + collectionSlug);
    }

    uploadFileBooks(file: File, type: any, bookSlug: string): Observable<UploadFileData> {
        let fileType: string;

        if (type == 'pdf') {
            fileType = '/pdf';
        }
        if (type == 'audio') {
            fileType = '/audio';
        }
        if (type == 'image') {
            fileType = '/image';
        }
        if (type == 'severalfiles') {
            fileType = '/severalfiles';
        }

        return this.uploadFile(file, '/' + this.bookPath + '/' + bookSlug + '/' + fileType);

    }

    uploadFile(file: File, path: string): Observable<UploadFileData> {
        let id = (Math.random().toString(36).substring(2) + 'x550s') + '_';

        const filePath = this.filePath + path;
        const fileRef = this.afStorage.ref(filePath).child(`${id + file.name}`);

        const task = fileRef.put(file);
        let uploadFileData: UploadFileData = {
            downloadurl: '',
            progress: undefined
        }
        return Observable.create(
            observer => {
                task.snapshotChanges().pipe(
                    finalize(() => {
                        fileRef.getDownloadURL().subscribe(
                            data => {
                                uploadFileData.downloadurl = data;
                                observer.next(uploadFileData);
                                observer.complete();
                                this.mensajes('Subida completa');
                            },
                            error => {
                                this.mensajes(error.message);
                            }
                        );

                    })
                )
                    .subscribe()
            }
        );



        // let ref: AngularFireStorageReference = this.afStorage.ref(this.filePath + path).child(`${id + file.name}`);
        // let task: AngularFireUploadTask = ref.put(file);
        // let uploadFileData: UploadFileData = {
        //     downloadurl: '',
        //     progress: undefined
        // }
        // return Observable.create(
        //     observer => {
        //         task.then(
        //             (snapshot) => {
        //                 task.downloadURL().subscribe(
        //                     data => {
        //                         ref.getDownloadURL().subscribe(
        //                             data => {
        //                                 uploadFileData.downloadurl = data;
        //                                 observer.next(uploadFileData);
        //                                 observer.complete();
        //                                 this.mensajes('Subida completa');
        //                             },
        //                             error => {
        //                                 this.mensajes(error.message);
        //                             }
        //                         );
        //                     }
        //                 );
        //             },
        //             (error) => { this.mensajes(error.message); }
        //         );
        //     }
        // );
    }


    mensajes(mesage: string): any {
        this.snackBar.open(mesage, '', {
            duration: 2000
        });
    }
}   