import { Injectable } from "@angular/core";
import { HttpService } from "../../core/http.service";
import { MatSnackBar } from "@angular/material";
import { Page } from "./page.model";
import { Observable } from "rxjs/Observable";
import { Book } from "./book.model";

@Injectable()
export class BookService {

    static END_POINT = "/books";
    static COLLECTION = "/collection";
    static ADD_BOOK = "/adduserbook";
    static USER = "/user";
    static GET = "/get";

    constructor(private httpService: HttpService, public snackBar: MatSnackBar) {

    }

    createCollection(book: Book): Observable<Book> {
        return this.httpService.authToken().post(BookService.END_POINT, book).map(
            data => {
                this.successful();
                return data;
            }
        );

    }

    readBook(id: string): Observable<Book> {
        return this.httpService.authToken().get(BookService.END_POINT + '/' + id);
    }

    readBooksAll(page: number, size: number, term:String): Observable<Page> {
        return this.httpService.authToken().get(BookService.END_POINT + BookService.GET + '?page=' + page + '&size=' + size + '&filter=' + term);
    }

    readPdf(url:string){
        return this.httpService.authToken().get(url);
    
    }

    getBooksByCollection(id: string, page: number, size: number): Observable<Page> {
        return this.httpService.authToken().get(BookService.END_POINT + BookService.GET + BookService.COLLECTION + '/' + id + '?page=' + page + '&size=' + size);
    }

    readUserBook(id: string, userid: string): any {
        return this.httpService.authToken().get(BookService.END_POINT + '/' + id + BookService.USER + '/' + userid);
    }

    updateBook(id: string, book: Book): Observable<Book> {
        return this.httpService.authToken().put(BookService.END_POINT + '/' + id, book).map(
            data => {
                this.successful();
                return data;
            }
        );
    }

    deleteBook(id: string): Observable<boolean> {
        return this.httpService.authToken().delete(BookService.END_POINT + '/' + id);
    }

    createBook(book: Book): Observable<Book> {
        return this.httpService.authToken().post(BookService.END_POINT, book).map(
            data => {
                this.successful();
                return data;
            }
        );
    }

    deleteCollection(id: string) {
        return this.httpService.authToken().delete(BookService.END_POINT + '/' + id);
    }


    addUserBookToUserCollection(code: string, userId: string) {
        return this.httpService.authToken().post(BookService.END_POINT + BookService.ADD_BOOK + '/' + code + BookService.USER + '/' + userId);
    }

    readUserBooksOfACollection(userId: string, collectionId: string, page: number, size: number, filter: string): Observable<Page> {
        return this.httpService.authToken().get(BookService.END_POINT + BookService.USER + '/' + userId + BookService.COLLECTION + '/' + collectionId + BookService.GET + '?page=' + page + '&size=' + size + '&filter=' + filter);
    }

    private successful() {
        this.snackBar.open('Successful', '', {
            duration: 2000
        });
    }

}