import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../shared/user.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserValidatorMessages } from '../shared/validators/userValidatorsMessages';
import { ParentErrorStateMatcher, PasswordValidator } from '../shared/validators/password.validator';

@Component({
    selector: 'reset-password-user',
    templateUrl: 'resetPasswordUser.component.html',
    styleUrls: ['resetPasswordUser.component.css']
})
export class ResetPasswordUserComponent implements OnInit {
    loading: boolean;
    isEmailForm: boolean;
    isResetForm: boolean;
    emailResetForm: FormGroup;
    setNewPassForm: FormGroup;

    @Input()
    private resetPassCodeInput: string;

    @Input()
    private resetPassEmailInput: string;

    @Output()
    resetPassOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

    userValidatorMessages = new UserValidatorMessages();
    parentErrorStateMatcher = new ParentErrorStateMatcher();
    matching_passwords_group: FormGroup;

    constructor(private userService: UserService) { }

    ngOnInit(): void {
        this.loading = false;
        this.isEmailForm = false;
        if (!this.resetPassCodeInput && !this.resetPassEmailInput) {
            this.isEmailForm = true;
            this.prepareEmailForm();
        } else {
            this.isResetForm = true;
            this.prepareSetNewPassForm();
        }

    }

    emailForm(formData: FormBuilder) {
        this.loading = true;
        this.userService.rememberPassWord(btoa(formData['email'])).subscribe(
            data => {
                this.loading = false;
                this.resetPassOutput.emit(false);
            },error =>{
                this.loading = false;
            }
        );
    }

    prepareEmailForm() {
        this.emailResetForm = new FormGroup({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ]))
        })
    }

    prepareSetNewPassForm() {
        this.matching_passwords_group = new FormGroup({
            password: new FormControl('', Validators.compose([
                Validators.minLength(5),
                Validators.required
               // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
            ])),
            confirm_password: new FormControl('', Validators.required)
        }, (formGroup: FormGroup) => {
            return PasswordValidator.areEqual(formGroup);
        });
        this.setNewPassForm = new FormGroup({
            matching_passwords: this.matching_passwords_group,
            termsaccept: new FormControl(false, Validators.compose([
                Validators.required
                //Validators.pattern('true')
            ]))
        });
    }

    sendResetPassWordForm(formData: FormBuilder) {
        this.loading = true;
        this.userService.resetPassword(this.resetPassCodeInput, this.resetPassEmailInput, btoa(formData['matching_passwords']['password'])).subscribe(
            result => {
                this.loading = false;
                this.resetPassOutput.emit(false);
            }, error => {
                this.loading = false;
            }
        );
    }

    isRememberPass(): any {
        this.resetPassOutput.emit(false);
    }

    cancelReset() {
        this.isEmailForm = true;
    }
}