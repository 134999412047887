import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Http, ResponseContentType } from "@angular/http";
import { HomeComponent } from "../home.component";
import { BookService } from "../shared/book.service";
import { UserService } from "../shared/user.service";
import { Book } from "../shared/book.model";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BookMedia } from "../shared/bookmedia.model";

@Component({
    selector: `book`,
    styleUrls: [`book.component.css`],
    templateUrl: `book.component.html`

})

export class BookComponent implements OnInit {
    static URL = 'book/:id';
    books: any[];
    book: Book;
    outPuvideos: [BookMedia];
    trustedDashboardUrl : SafeUrl;
    @ViewChild('pdfViewer') pdfViewer
    private responseType: ResponseContentType;
    id: string;
    userId: string;
    constructor(private userService: UserService, public domSanitizer: DomSanitizer, private bookService: BookService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.id = activatedRoute.snapshot.params['id'];
        this.userId = '';
        this.outPuvideos = undefined;
        //this.book.pdf = null;
        
    }

    ngOnInit(): void {
        this.userService.readCurrentUser().subscribe(
            user => {
                this.userId = user.id;
                this.bookService.readUserBook(this.id, user.id).subscribe(
                    book => {
                        this.book = book;
                        if (this.book.pdf) {
                            this.book.pdf.length > 0 ? this.book.pdf : this.book.pdf = null;
                        }
                        if (this.book.audio) {
                            this.book.audio.length > 0 ? this.book.audio : this.book.audio = null;
                        }
                        if (this.book.videos) {
                            this.book.videos.length > 0 ? this.book.videos : this.book.videos = null;
                        }
                        if (this.book.severalFiles) {
                            this.book.severalFiles.length > 0 ? this.book.severalFiles : this.book.severalFiles = null;
                        }
                        this.outPutVideoUrl(this.book.videos);
                    }
                );
            }
        );
    }

    getVideoVimeo(url:string):SafeUrl{
        this.trustedDashboardUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        return this.trustedDashboardUrl;
    }

    outPutVideoUrl(videos: [BookMedia]) {
        if (videos) {
            let outPuvideos;
            outPuvideos = videos.map(x => Object.assign({}, x));
            outPuvideos.forEach(video => {
                if (video.src !== '') {
                    let videoId = video.src.split('/');
                    video.src = 'https://player.vimeo.com/video/' + videoId[3];
                }
            });

            this.outPuvideos = outPuvideos;
        }
    }

    public backCollection(id: String) {
        this.router.navigate([HomeComponent.URL, 'collection', id]);
    }

}

