import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Collection } from "../shared/collection.model";
import { CollectionsService } from "../shared/collections.service";
import { FirebaseUploadService } from "../shared/firebaseupload.service";
import { UtilsComponent } from "../../core/utils.component";
import { Ng2ImgMaxService } from "ng2-img-max";
@Component({
    templateUrl: `edit-createCollection.component.html`,
    styleUrls: [`edit-createCollection.component.css`]
})
export class EditCreateCollectionComponent implements OnInit {
    static URL = 'collectionDetails/:id';
    static NEW = 'collectionDetails/new';
    id: string;
    collection: Collection;
    itemInLoading: boolean;
    dropzoneActive: boolean = false;
    constructor(private ng2ImgMaxService: Ng2ImgMaxService, private firebaseUploadService: FirebaseUploadService, private router: Router, private activatedRoute: ActivatedRoute, private collectionService: CollectionsService, private utilsComponent: UtilsComponent) {
        this.id = activatedRoute.snapshot.params['id'];
        this.collection = {
            id: null,
            slug: null,
            name: null,
            image: null
        }
    }

    ngOnInit(): void {
        if (this.id != 'new') {
            this.SyncData();
        }
    }

    SyncData(): any {
        this.readCollection(this.id);
    }

    handleDropFiles(fileList: FileList) {
        this.uploadFile(null, fileList[0]);
    }

    setSlug(): void {
        this.collection.slug = this.utilsComponent.transform(this.collection.name);
    }

    dropzoneState($event: boolean) {
        this.dropzoneActive = $event;
    }

    uploadFile(event, file: File = null) {
        this.itemInLoading = true;
        let fileu: File;
        if (file) {
            fileu = file;
        } else {
            fileu = event.srcElement.files[0];
        }
        if (fileu != null) {
            this.updateCurrentFile(this.collection.image);
        }
        this.firebaseUploadService.uploadFileCollections(fileu, this.collection.slug).subscribe(
            file => {
                this.collection.image = file.downloadurl;
                this.save(this.collection.id);
                this.itemInLoading = false;
            }
        );
    }

    updateCurrentFile(imageUrl: string): any {
        if (imageUrl) {
            this.deleteFile(imageUrl);
        }
    }

    deleteFile(imageUrl: string): any {
        this.firebaseUploadService.deleteFile(imageUrl);
    }

    deleteImage(imageUrl: string) {
        this.deleteFile(imageUrl);
        this.collection.image = null;
        this.save(this.id);
    }

    readCollection(id: string): any {
        this.collectionService.readCollection(id).subscribe(
            data => {
                this.collection = data;
                //this.ng2ImgMaxService.resize([this.collection.image], 2000, 1000).subscribe((result) => {
                // });
            }
        );
    }

    canSave(): boolean {
        let canSave: boolean = false;
        if (this.collection.name) {
            if (!this.collection.slug) {
                this.setSlug();
                canSave = true;
            } else {
                canSave = true;
            }
        }
        return canSave;
    }

    save(id: string) {
        if (this.canSave()) {
            if (id) {
                this.collectionService.updateCollection(id, this.collection).subscribe(
                    data => {
                        this.SyncData();
                    }
                );
            } else {
                this.collectionService.createCollection(this.collection).subscribe(
                    data => {
                        this.id = data.id;
                        this.SyncData();
                    }
                );
            }
        }
    }

    close() {
        this.router.navigate(['home/collectionList']);
    }

    saveAndClose(id: string) {
        this.save(id);
        this.close();
    }

}