import { Component, OnInit } from "@angular/core";
import { BookService } from "../shared/book.service";

@Component({
    templateUrl: 'addBookStatus-dialog.component.html',
    styles: [`.mat-dialog-content {
        display: flex;
        flex-direction: column;
    }mat-spinner{background-color:transparent!important;}`]
})
export class AddBookStatusDialogComponent {

    bookCode: any;
    loading: boolean;
    success: boolean;
    message: String;

    constructor() {
        this.message = '';
        this.loading = true;
        this.success = false;
    }

}