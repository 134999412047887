import { Component, Input, OnInit } from '@angular/core';
import { BookService } from '../shared/book.service';
import { Book } from '../shared/book.model';
import { Collection } from '../shared/collection.model';
import { CollectionsService } from '../shared/collections.service';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'front-filter-data-results',
    templateUrl: 'front-filter-data-results.component.html'
})
export class FrontFilterDataResults implements OnInit {

    @Input()
    filterValue: Subject<string>;

    @Input()
    userId: string;

    collectionBooks: [Book];
    collections: Collection[];
    isCollectionsEmpty: boolean;
    isCollectionBooksEmpty: boolean;
    constructor(private router: Router, private bookService: BookService, private collectionsService: CollectionsService) {

    }

    ngOnInit(): void {
        this.filterValue.subscribe(
            data => {
                this.applyFilter(data);
            }
        );
    }

    applyFilter(filterValue: string) {
        this.readUserbooksInCollection(this.userId, null, 0, 25, filterValue);
        this.readUserCollections(this.userId, 0, 25, filterValue);
    }

    readUserbooksInCollection(userId: string, collectionid: string, page: number, size: number, filter: string = null): any {
        this.bookService.readUserBooksOfACollection(userId, collectionid, page, size, filter).subscribe(
            page => {
                this.collectionBooks = page.content;
                if (page.totalElements == 0) {
                    this.isCollectionBooksEmpty = true;
                } else {
                    this.isCollectionBooksEmpty = false;
                }
            }
        );
    }

    readUserCollections(userId: string, page: number, size: number, filter: string = null): any {
        this.collectionsService.readUserCollections(userId, page, size, filter).subscribe(
            page => {
                this.collections = page.content;
                if (page.totalElements == 0) {
                    this.isCollectionsEmpty = true;
                } else {
                    this.isCollectionsEmpty = false;
                }
            }
        );
    }

    collectionDetails(collectionId: String) {
        this.router.navigate(['home/collection/', collectionId]);
    }

    bookDetails(id: number) {
        this.router.navigate(['home/book/', id]);
    }
}