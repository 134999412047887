import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatCardModule
} from '@angular/material';
import { HttpService } from './http.service';
import { TokensService } from './tokens.service';
import { UserService } from '../home/shared/user.service';
import { CollectionsService } from '../home/shared/collections.service';
import { BookService } from '../home/shared/book.service';
import { FirebaseUploadService } from '../home/shared/firebaseupload.service';


@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatSortModule,
        MatTableModule
    ],
    declarations: [
    ],
    exports: [
    ],
    entryComponents: [

    ],
    providers: [
        HttpService,
        TokensService,
        UserService,
        CollectionsService,
        BookService,
        FirebaseUploadService
    ]
})
export class CoreModule {
  static entryComponents: any;
}
