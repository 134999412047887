import { Component, OnInit, OnDestroy } from "@angular/core";
import { BookService } from "../shared/book.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Book } from "../shared/book.model";
import { CollectionsService } from "../shared/collections.service";
import { Collection } from "../shared/collection.model";
import { BookMedia } from "../shared/bookmedia.model";
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseUploadService } from "../shared/firebaseupload.service";
import { UtilsComponent } from "../../core/utils.component";
import { MatSnackBar } from "@angular/material";
@Component({
    templateUrl: `edit-createBook.component.html`,
    styleUrls: [`edit-createBook.component.css`]
})
export class EditCreateBookComponent implements OnInit {

    static URL = 'bookDetails/:id';
    static NEW = 'bookDetails/new';
    id: string;
    book: Book;
    collections: Collection[];
    selectedValue: string;
    // Media Items Temporal array
    videos: [BookMedia];
    // Temporal url videos
    outPuvideos: [BookMedia];
    audio: [BookMedia];
    pdfs: [BookMedia];
    severalfiles: [BookMedia];
    pdfTemFiles: [any];
    audioTemFiles: [any];
    dropzoneActive: boolean = false;
    itemInLoading: boolean;
    itemOutLoading: boolean;
    bookImageLoading: boolean;
    itemsToLoad: any[];
    constructor(public snackBar: MatSnackBar, private utilsComponent: UtilsComponent, private firebaseUploadService: FirebaseUploadService, public domSanitizer: DomSanitizer, private collectionsService: CollectionsService, private bookService: BookService, private router: Router, private activatedRoute: ActivatedRoute) {
        this.id = activatedRoute.snapshot.params['id'];
        this.book = {
            id: null,
            active: true,
            slug: '',
            collectionId: '',
            title: '',
            code: '',
            image: '',
            audio: null,
            videos: null,
            pdf: null
        }
        this.videos = undefined;
        this.outPuvideos = undefined;
        this.audio = undefined;
        this.pdfs = undefined;
        this.severalfiles = undefined;
        this.pdfTemFiles = undefined;
        this.audioTemFiles = undefined;
        this.itemOutLoading = false;
        this.bookImageLoading = false;
        this.itemInLoading = false;
    }

    ngOnInit(): void {
        this.syncData();
    }


    syncData(): void {
        if (this.id != 'new') {
            this.bookService.readBook(this.id).subscribe(
                book => {
                    this.book = book;
                    this.selectedValue = book.collectionId;
                    this.videos = book.videos;
                    this.audio = book.audio;
                    this.pdfs = book.pdf;
                    this.severalfiles = book.severalFiles;
                    if (this.videos) {
                        this.outPutVideoUrl(this.videos);
                    }
                }
            );
        }

        this.collectionsService.readCollectionsAll().subscribe(
            collections => {
                this.collections = collections;
            }
        );

    }

    /**
    * Get and the book´s name, transform and set to slug for set the book´s folder in the cloud
    */
    setSlug(): void {
        this.book.slug = this.utilsComponent.transform(this.book.title);
    }
    /**
    * Set the drop zone state 
    */
    dropzoneState($event: boolean) {
        this.dropzoneActive = $event;
    }
    /**
    * Handle the drop files
    */
    handleDropFiles(fileList: FileList, type: string) {
        if (type == 'image') {
            this.uploadFile(null, type, null, null, fileList);
        } else {
            this.itemOutLoading = true;
            let i: number = 0;
            // Items to load is for each file loading animation
            this.itemsToLoad = Array(fileList.length);
            let size = fileList.length;
            Array.from(fileList).forEach(
                item => {
                    if (!this.checkFileFormatSize(item, type)) {
                        return;
                    }
                    this.firebaseUploadService.uploadFileBooks(item, type, this.book.slug).subscribe(
                        file => {
                            this.addMediaItemFileBox(item, type, file.downloadurl);
                            this.itemOutLoading = false;
                            i++;
                            this.itemsToLoad = this.itemsToLoad.slice(1);
                            if (size == 1) {
                                this.saveBook(this.book.id);
                            } else if (size == i) {
                                this.saveBook(this.book.id);
                            }
                        }, error => {
                            this.saveBook(this.book.id);
                        }
                    );
                }

            );
        }
    }
    /**
    * Check if the files are in the correct format
    */
    checkFileFormatSize(item: File, type: string): boolean {
        // var maxSize = 4000000; //4000000 B
        // if (maxSize < item.size) {
        //     this.stopBookMediaFileUpload('Archivo demasiado grande');
        //     return;
        // }
        switch (type) {
            case 'pdf':
                if (item.type != "application/pdf") {
                    this.stopBookMediaFileUpload('Archivo con formato incorrecto');
                    return false;
                }
                break;
            case 'audio':
                if (item.type != "audio/mp3") {
                    this.stopBookMediaFileUpload('Archivo con formato incorrecto');
                    return false;
                }
                break;
        }
        return true;
    }
    /**
    * Stop the upload file 
    */
    stopBookMediaFileUpload(menssage: string): any {
        this.itemOutLoading = false;
        this.itemsToLoad = this.itemsToLoad.slice(1);
        this.mensajes(menssage);
    }

    /**
    * Upload file 
    */

    uploadFile(event, type: any, i: number, temporalMediaItems: [BookMedia], fileList: FileList) {
        let fileu: any;
        if (event) {
            fileu = event.srcElement.files[0];
        } else {
            fileu = fileList[0];
        }
        if (type == 'image') {
            this.bookImageLoading = true;
        }

        this.itemInLoading = true;

        if (fileu != null) {
            this.updateCurrentFile(i, temporalMediaItems);
        }

        this.firebaseUploadService.uploadFileBooks(fileu, type, this.book.slug).subscribe(
            file => {
                if (temporalMediaItems) {
                    temporalMediaItems[i].src = file.downloadurl;
                    temporalMediaItems[i].title = fileu.name;
                }
                if (type == 'image') {
                    this.book.image = file.downloadurl;
                }

                this.saveBook(this.book.id);
                this.bookImageLoading = false;
                this.itemInLoading = false;
            }
        );
    }

    /**
    * Update file and delete the current file if exist
    */
    updateCurrentFile(i: number, temporalMediaItems: [BookMedia]) {
        if (temporalMediaItems != null) {
            if (temporalMediaItems[i].src != null) {
                this.deleteFile(temporalMediaItems[i].src);
            }
        }
    }
    /**
    * Delete the book´s image
    */
    deleteImage(imageUrl: string) {
        this.deleteFile(imageUrl);
        this.book.image = null;
        this.saveBook(this.id);
    }

    /**
    * Calls delete file 
    */
    deleteFile(url: string) {
        this.firebaseUploadService.deleteFile(url);
    }

    /**
    * Save Book
    */
    saveBook(id: string) {
        if (id) {
            this.checkSrcFilesMediaBooks();
            this.bookService.updateBook(id, this.book).subscribe(
                data => {
                    this.syncData();
                }
            );
        } else {
            this.checkSrcFilesMediaBooks();
            this.bookService.createBook(this.book).subscribe(
                data => {
                    this.id = data.id;
                    this.syncData();
                }
            );
        }
    }

    /**
    * Check if the temporal items´s sources are empty
    */
    checkSrcFilesMediaBooks(): void {
        this.cleanTemporalMediaBooks(this.book.videos, 'videos');
        this.cleanTemporalMediaBooks(this.book.audio, 'audio');
        this.cleanTemporalMediaBooks(this.book.pdf, 'pdf');
        this.cleanTemporalMediaBooks(this.book.severalFiles, 'severalFiles');
    }

    /**
    * Delete temporal media item if it src is empty
    */
    cleanTemporalMediaBooks(bookMediaArray: [BookMedia], type: string): any {
        let newbookMediaArray: [BookMedia];

        if (bookMediaArray) {
            bookMediaArray.forEach((item, index) => {

                if (item.src) {
                    console.log(item);
                    if (newbookMediaArray) {
                        newbookMediaArray.push(item);
                        //this.deleteMediaItemFromArray(index, bookMediaFiles);
                        console.log(item.src);
                    } else {
                        console.log(item.src);
                        newbookMediaArray = [
                            {
                                src: item.src,
                                title: item.title,
                                image: item.image
                            }
                        ];
                    }
                }
            });
        }

        switch (type) {
            case 'pdf':
                this.book.pdf = newbookMediaArray;
                break;
            case 'audio':
                this.book.audio = newbookMediaArray;
                break;
            case 'videos':
                this.book.videos = newbookMediaArray;
                break;
            case 'severalFiles':
                this.book.severalFiles = newbookMediaArray;
                break;
        }

    }

    /**
    * Add Empty temporal media item 
    */
    addMediaItemEmptyBox(type: string, mediaEmptyBoxItem: BookMedia = null) {
        let newMediaEmptyBoxItem: BookMedia = {
            src: null,
            image: '',
            title: ''
        }

        if (mediaEmptyBoxItem) {
            newMediaEmptyBoxItem = mediaEmptyBoxItem;
        }

        switch (type) {
            case 'pdf':
                this.pdfs = this.setMediaArrays(this.pdfs, newMediaEmptyBoxItem);
                this.book.pdf = this.pdfs;
                break;
            case 'audio':
                this.audio = this.setMediaArrays(this.audio, newMediaEmptyBoxItem);
                this.book.audio = this.audio;
                break;
            case 'videos':
                this.videos = this.setMediaArrays(this.videos, newMediaEmptyBoxItem);
                this.book.videos = this.videos;
                break;
            case 'severalfiles':
                this.severalfiles = this.setMediaArrays(this.severalfiles, newMediaEmptyBoxItem);
                this.book.severalFiles = this.severalfiles;
                break;
        }
    }

    setMediaArrays(temporalMediaItems: [BookMedia], newMediaEmptyBoxItem: BookMedia): [BookMedia] {
        if (temporalMediaItems) {
            temporalMediaItems.push(newMediaEmptyBoxItem);
        } else {
            temporalMediaItems = [newMediaEmptyBoxItem];
        }
        return temporalMediaItems;
    }

    addMediaItemFileBox(file: File, type: string, url: string) {
        let newMediaEmptyBoxItem: BookMedia = {
            src: url,
            image: '',
            title: file.name
        }
        this.addMediaItemEmptyBox(type, newMediaEmptyBoxItem);
    }

    deleteMediaItem(i: number, temporalMediaItems: [BookMedia], isVideo: boolean = false) {
        if (isVideo) {
            this.outPutVideoUrl(this.videos);
        } else {
            if (temporalMediaItems[i].src != '' && temporalMediaItems[i].src != null) {
                this.deleteFile(temporalMediaItems[i].src);
            }
        }
        this.deleteMediaItemFromArray(i, temporalMediaItems);
        if (this.book.id) {
            this.saveBook(this.book.id);
        }
    }

    deleteMediaItemFromArray(i: number, temporalMediaItems: [BookMedia]) {
        temporalMediaItems.splice(i, 1);
    }

    outPutVideoUrl(videos: [BookMedia]) {
        if (videos) {
            let outPuvideos;
            outPuvideos = videos.map(x => Object.assign({}, x));
            outPuvideos.forEach(video => {
                if (video.src) {
                    let videoId = video.src.split('/');
                    video.src = 'https://player.vimeo.com/video/' + videoId[3];
                } else {
                    video.src = null;
                }
            });
            this.outPuvideos = outPuvideos;
        }
    }

    canSave(): boolean {
        let canSave: boolean = false;
        if (this.book.code && this.book.title && this.book.collectionId) {
            canSave = true;
        }
        return canSave;
    }

    close() {
        this.router.navigate(['home/booklist']);
    }

    mensajes(mesage: string): any {
        this.snackBar.open(mesage, '', {
            duration: 2000
        });
    }
}