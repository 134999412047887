import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {
  MatAutocompleteModule, MatButtonModule, MatButtonToggleModule,
  MatCardModule, MatCheckboxModule, MatChipsModule,
  MatDatepickerModule, MatDialogModule, MatExpansionModule,
  MatGridListModule, MatIconModule, MatInputModule,
  MatListModule, MatMenuModule, MatNativeDateModule,
  MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule,
  MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule,
  MatSnackBarModule, MatSortModule, MatTableModule,
  MatTabsModule, MatToolbarModule, MatTooltipModule,
  MatStepperModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollectionComponent } from './home/collections/collection.component';
import { CollectionsComponent } from './home/collections/collections.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddBookComponent } from './home/collections/addBook.component';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';

import { FileDropDirective } from './home/admin/file-drop.directive';
import { UtilsComponent } from './core/utils.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { SafeUrlPipe } from './home/shared/safeUrlPipe.component';
import { KeysPipe } from './home/shared/keysPipe.component';
import { environment } from '../environments/environment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  declarations: [
    AppComponent,
    AppRoutingModule.COMPONENT_FACTORY,
    AppRoutingModule.COMPONENTS,
    CollectionComponent,
    CollectionsComponent,
    AddBookComponent,
    FileDropDirective,
    SafeUrlPipe,
    KeysPipe
  ],
  imports: [
    PdfViewerModule,
    BrowserModule,
    Ng2ImgMaxModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    FormsModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    CoreModule
  ],
  entryComponents: [AppRoutingModule.COMPONENT_FACTORY],
  providers: [CookieService, UtilsComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
