import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'filter-data',
    template: `
    <section class="customFilterInput"  fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
    </section>
    `
})
export class FilterDataComponent {

    @Output()
    textOutput: EventEmitter<String> = new EventEmitter<String>();

    applyFilter(event) {
        this.textOutput.emit(event);
    }

}