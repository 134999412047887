import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AddBookStatusDialogComponent } from "./addBookStatus-dialog.component";
import { BookService } from "../shared/book.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelpAddBookDialogComponent } from './help-addBook-dialog-component';
@Component({
    selector: `addBook`,

    template: `
    <form [formGroup]="addBookForm" fxLayoutGap="1%" fxLayout="row" fxLayoutAlign="center center" (ngSubmit)="addBook(addBookForm.value)" >
        <div fxFlex="79" fxFlex.xs="59">
        <mat-form-field class="customOutlineInput" appearance="outline" fxFlex="100">
            <mat-label>Escribir código</mat-label>
            <input matInput #bookcode placeholder="Escribir código" value="" formControlName="code" required>
            </mat-form-field>
        </div>
        <div fxFlex.xs="39">
            <button type="submit" [disabled]="!addBookForm.valid" mat-raised-button color="primary">
                <span>Activar libro</span>
            </button>
        </div>
    </form>
    <div class="add_book_help"><a [routerLink]="" (click)="openHelp()">¿Necesitas ayuda?</a></div>
`})
export class AddBookComponent implements OnInit {


    @ViewChild('bookcode') bookcode: ElementRef;
    @Output() updateData = new EventEmitter();
    @Input('userId') userId: string;

    addBookForm: FormGroup;
    bc: any;

    constructor(private matDialog: MatDialog, private bookService: BookService, private formBuilder: FormBuilder) {
        this.bookcode = null;
    }

    ngOnInit(): void {
        this.prepareForm();
    }

    prepareForm(): any {
        this.addBookForm = this.formBuilder.group({
            code: ['', Validators.required]
        });
    }

    addBook(dataForm: FormBuilder) {

        const dialogRef = this.matDialog.open(AddBookStatusDialogComponent);
        dialogRef.componentInstance.loading = true;
        this.bookService.addUserBookToUserCollection(dataForm['code'], this.userId).subscribe(
            data => {
                dialogRef.componentInstance.bookCode = dataForm['code'];
                dialogRef.componentInstance.message = "Su libro de ha activado con exito";
                dialogRef.componentInstance.loading = false;
                this.updateData.emit(true);
            }, error => {
                switch (error.httpError) {
                    case 404:
                        dialogRef.componentInstance.message = "Código no valido";
                        break;
                    case 400:
                        dialogRef.componentInstance.message = "El código introducido pertenece a un libro ya activado";
                        break;
                    default:
                        dialogRef.componentInstance.message = "Error código:" + error.httpError + " intententalo más tarde";
                        break;
                }
                dialogRef.componentInstance.loading = false;
            }
        );
        this.addBookForm.controls['code'].setValue('');
        this.addBookForm.controls['code'].markAsPending();
    }

    openHelp(){
        const dialogRef =this.matDialog.open(HelpAddBookDialogComponent);
    }

}