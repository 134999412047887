import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams, ResponseContentType } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Token } from './token.model';
import { Role } from './role.model';
import { Error } from './error.model';
import { MatSnackBar, _MatOptgroupMixinBase } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { _throw } from 'rxjs/observable/throw';
import { throwError } from 'rxjs';

@Injectable()
export class HttpService {

    //static API_END_POINT = 'https://18.191.120.180:8443/api/v0';
    //static API_END_POINT = 'http://localhost:8080/api/v0';
    //static API_END_POINT = 'https://testapp-1832631356.us-east-2.elb.amazonaws.com:8443/api/v0';
    static API_END_POINT = 'https://app.misrecursos.es/api/v0';
    static UNAUTHORIZED = 401;

    private token: Token;

    private params: URLSearchParams;

    private headers: Headers;

    private email: string;

    private responseType: ResponseContentType;

    constructor(private http: Http, private snackBar: MatSnackBar, private router: Router, private _cookieService: CookieService) {
        this.resetOptions();
        this.token = {
            roles: undefined,
            token: undefined
        }
    }

    private resetOptions(): void {
        this.headers = new Headers();
        
        this.params = new URLSearchParams();
        this.responseType = ResponseContentType.Text;
    }

    synchronizeAuthorized(endPoint: string): Observable<any> {
        return this.authToken().get(endPoint).map(
            token => token,
            error => this.logout()
        );
    }

    getCurrentRole(END_POINT: string): Observable<Array<Role>> {
        return this.get(END_POINT + '/' + this.getTempToken().token).map(
            croles => croles,
            error => error
        );
    }

    getRoles(): Array<Role> {
        if (this.token !== undefined) {
            return this.token.roles;
        } else {
            return undefined;
        }
    }

    isLogged(): boolean {
        if (this.getTempToken() != null) {
            this.token = this.getTempToken();
            return true;
        }
        if (this.token == undefined) {
            return false;
        }
        return false;
    }

    getUseremail(): string {
        if (this.email == undefined) {
            this.email = this._cookieService.get('email');
            if (this.email == undefined) {
                this.email = sessionStorage.getItem('email');
            }
        }
        return this.email;
    }

    logout(): void {
        this.token = undefined;

        this._cookieService.delete('tokenid', "/");
        this._cookieService.delete('email', "/");
        this._cookieService.delete('rememberme', "/");
        this.router.navigate(['welcome']);
    }

    login(email: string, password: string, remember: boolean, endPoint: string): Observable<any> {
        return this.authBasic(email, password).post(endPoint).map(
            token => {
                this.token = token;
                this.email = email;
                if (remember) {
                    this._cookieService.set('tokenid', this.token.token);
                    this._cookieService.set('email', this.email);
                    this._cookieService.set('rememberme', "true");
                } else {
                    this._cookieService.set('tokenid', this.token.token, this.timeSessionCookie(), "/");
                    this._cookieService.set('email', this.email, this.timeSessionCookie(), "/");
                    this._cookieService.set('rememberme', "false", this.timeSessionCookie(), "/");
                }
            },
            error => this.logout()
        );
    }
    timeSessionCookie(): number {
        let curentTime = new Date();
        let expireTime = curentTime.getTime() + 3600000;
        curentTime.setTime(expireTime);
        return curentTime.getDay();
    }

    param(key: string, value: string): HttpService {
        this.params.append(key, value);
        return this;
    }

    header(key: string, value: string): HttpService {
        this.headers.append(key, value);
        return this;
    }

    authBasic(email: string, password: string): HttpService {
        this.headers.append('Authorization', 'Basic ' + btoa(email + ':' + password));
        return this;
    }

    authToken(): HttpService {
        let tokenValue = '';
        if (this.token !== undefined) {
            tokenValue = this.token.token;
            if (this._cookieService.get("rememberme") === "false") {
                if (this._cookieService.get("tokenid") && this._cookieService.get("email")) {
                    this._cookieService.delete('tokenid');
                    this._cookieService.delete('email');
                }
                this._cookieService.set('tokenid', this.token.token, this.timeSessionCookie(), "/");
                this._cookieService.set('email', this.email, this.timeSessionCookie(), "/");
            }
        }
        this.headers.append('Authorization', 'Basic ' + btoa(tokenValue + ':' + ''));
        return this;
    }

    getTempToken(): Token {
        let token: Token = {
            token: '',
            roles: undefined
        }
        let tokenValue;
        let cookieToken = this._cookieService.get('tokenid');

        if (cookieToken) {
            tokenValue = cookieToken;
        }
        if (tokenValue !== undefined) {
            token.token = tokenValue;
            token.roles = this.getRoles();
            return token;
        } else {
            return null;
        }
    }

    pdf(): HttpService {
        this.responseType = ResponseContentType.Blob;
        this.headers.append('Accept', 'application/pdf');
        return this;
    }

    get(endpoint: string): Observable<any> {
        return this.http.get(HttpService.API_END_POINT + endpoint, this.createOptions()).map(
            response => this.extractData(response)).catch(
                error => {
                    return this.handleError(error);
                });
    }

    post(endpoint: string, body?: Object): Observable<any> {
        return this.http.post(HttpService.API_END_POINT + endpoint, body, this.createOptions()).map(
            response => this.extractData(response)).catch(
                error => {
                    return this.handleError(error);
                });
    }

    delete(endpoint: string): Observable<any> {
        return this.http.delete(HttpService.API_END_POINT + endpoint, this.createOptions()).map(
            response => this.extractData(response)).catch(
                error => {
                    return this.handleError(error);
                });
    }

    put(endpoint: string, body?: Object): Observable<any> {
        return this.http.put(HttpService.API_END_POINT + endpoint, body, this.createOptions()).map(
            response => this.extractData(response)).catch(
                error => {
                    return this.handleError(error);
                });
    }

    patch(endpoint: string, body?: Object): Observable<any> {
        return this.http.patch(HttpService.API_END_POINT + endpoint, body, this.createOptions()).map(
            response => this.extractData(response)).catch(
                error => {
                    return this.handleError(error);
                });
    }

    private createOptions(): RequestOptions {
        const options: RequestOptions = new RequestOptions({
            headers: this.headers,
            params: this.params,
            responseType: this.responseType
        });
        this.resetOptions();
        return options;
    }

    private extractData(response: Response): any {
        const contentType = response.headers.get('content-type');
        if (contentType) {
            if (contentType.indexOf('application/pdf') !== -1) {
                return new Blob([response.blob()], { type: 'application/pdf' });
            } else if (contentType.indexOf('application/json') !== -1) {
                return response.json();
            }
        } else if (response.text()) {
            return response.text();
        } else {
            return response;
        }
    }


    private handleError(response: Response): any {
        let error: Error;
        if (response.status === HttpService.UNAUTHORIZED) {
            this.logout();
        }
        try {
            error = {
                httpError: response.status, exception: response.json().exception,
                message: response.json().message, path: response.json().path
            };
            this.snackBar.open(error.message, 'Error', {
                duration: 8000
            });
            return throwError(error);

        } catch (e) {
            this.snackBar.open(response.toString(), 'Error', {
                duration: 8000
            });
            return throwError(response);
        }
    }
}
