import { Component } from '@angular/core';

@Component({
    selector: '',
    template: `
    <h3 mat-dialog-title>
        Código de Activación
    </h3>
    <mat-dialog-content>
    <p>El código de activación es una serie alfanumérica que te dará acceso a tu contenido digital. Cada código de activación
        corresponde a los recursos digitales de un libro específico.</p>
    <p>El código de activación lo encontrarás en la primera página del libro o en una tarjeta adicional.
    </p>
    <p>Asegúrate de que los caracteres introducidos son los correctos, respetando las mayúsculas y minúsculas y sin incluir espacios
        ni caracteres especiales.
    </p>
    <p>Si tienes cualquier incidencia con nuestros productos o servicios, ponte en contacto con nuestro Centro de Gestión de
        Clientes:
    </p>
    <ul>
        <li><b>Correo electrónico:</b> gestion@misrecursos.es</li>
        <li><b>Teléfono:</b> 961462765</li>
        <li><b>Horario de atención:</b> Lunes a viernes de 09:00 a 14:00</li>
    </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close cdkFocusInitial color="primary">Cerrar</button>
    </mat-dialog-actions>`
})
export class HelpAddBookDialogComponent {

}