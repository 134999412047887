import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { TokensService } from '../../core/tokens.service';
import { Router } from '@angular/router';
import { HomeComponent } from '../home.component';
import { UserValidatorMessages } from '../shared/validators/userValidatorsMessages';

@Component({
    selector: 'login-user',
    templateUrl: 'loginUser.component.html',
    styleUrls: ['loginUser.component.css']
})
export class LoginUserComponent implements OnInit {

    rememberme: boolean;
    loading: boolean;
    loginForm: FormGroup;
    userValidatorMessages = new UserValidatorMessages();

    /*Register link*/
    @Output()
    registerOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

    /*Remember Pass link*/
    @Output()
    rememberPassOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService, private tokensService: TokensService) {

    }

    ngOnInit(): void {
        this.prepareLonginForm();
    }

    prepareLonginForm(): any {
        this.loginForm = this.formBuilder.group({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),
            password: ['', Validators.required]
        });
    }

    login(formData: FormBuilder) {
        this.loading = true;
        this.tokensService.login(formData['email'], formData['password'], this.rememberme).subscribe(
            () => {
                this.loading = false;
                this.router.navigate([HomeComponent.URL]);
            }, error => {
                this.loading = false;
            }
        );
    }

    /*Register link*/
    goRegister(): any {
        this.registerOutput.emit(true);
    }

    /*Remember Pass link*/
    goRememberPass(): any {
        this.rememberPassOutput.emit(true);
    }
}