import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatSnackBar, DateAdapter } from '@angular/material';

import { TokensService } from '../core/tokens.service';
import { Router, ActivatedRoute } from '@angular/router';
import { style, animate, AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { UserService } from '../home/shared/user.service';
import { UtilsComponent } from '../core/utils.component';

import { FormBuilder } from '@angular/forms';



@Component({
  styleUrls: [`welcome.component.css`],
  templateUrl: `welcome.component.html`
})
export class WelcomeComponent implements OnInit {

  static URL = 'welcome';

  image: string;
  imageNext: string;
  images: any[];

  isRememberme: boolean;
  isRegister: boolean;
  isResetPass: boolean;

  resetPassCode: string;
  resetPassEmail: string;


  @ViewChild('backwelcome') elementRef: ElementRef;
  private player: AnimationPlayer;
  isLogin: boolean;

  constructor(private adapter: DateAdapter<any>, private formBuilder: FormBuilder, private activatedRute: ActivatedRoute, public snackBar: MatSnackBar, private utils: UtilsComponent, private userService: UserService, public dialog: MatDialog, private tokensService: TokensService, private router: Router, private animationBuilder: AnimationBuilder) {
    
    adapter.setLocale('es');

    this.resetPassCode = null;
    this.resetPassEmail = null;

    this.isRememberme = false;
    this.isResetPass = false;

    this.isRegister = false;

    this.image = 'assets/images/background_wellcome_1.jpg';
    this.imageNext = 'assets/images/background_wellcome_2.jpg';
    this.images = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10
    ];

  }

  ngOnInit(): void {
    this.initSlider();
    this.checkIsLogin(null);
    this.creckIsResetPass();
  }

  creckIsResetPass(): any {
    this.activatedRute.queryParams.subscribe(
      data => {
        if (data['resetpassword']) {
          this.resetPassCode = data['resetpassword'];
          this.resetPassEmail = data['resetpasswordemail'];
          this.checkIsLogin(null);
          this.isResetPass = true;
        }
      }
    );
  }

  checkIsRegister(event): any {
    this.isRegister = this.conmmuterOption(this.isRegister, event);
    this.checkIsLogin(null);
  }

  checkIsLogin(event): any {
    this.isLogin = this.conmmuterOption(this.isLogin, event);
  }

  checkIsRememberPass(event): any {
    this.isResetPass = this.conmmuterOption(this.isResetPass, event);
    this.checkIsLogin(null);
    this.resetPassCode = null;
    this.resetPassEmail = null;
  }

  conmmuterOption(option: boolean, event): boolean {
    if (!event) {
      option ? option = false : option = true;
    } else {
      option = event;
    }
    return option;
  }

  initSlider(): any {
    this.image = 'assets/images/background_wellcome_1.jpg';
    if (this.player) {
      this.player.destroy();
    }
    let animationFactory;
    for (let i = 0; i < this.images.length; i++) {
      if (i == 1) {
        animationFactory = this.animationBuilder
          .build([
            style({ 'background-image': 'url(' + this.image + ')' }),
            animate(200, style({ 'background-image': 'url(' + this.image + ')' }))
          ]);
        i + 1;
        this.player = animationFactory.create(this.elementRef.nativeElement);
        this.player.play();
      }

      setTimeout(() => {
        this.image = 'assets/images/background_wellcome_' + this.images[i] + '.jpg';
        if (i != this.images.length) {
          this.imageNext = 'assets/images/background_wellcome_' + (this.images[i] + 1) + '.jpg';
        }
        animationFactory = this.animationBuilder
          .build([
            style({ 'background-image': 'url(' + this.image + ')' }),
            animate(200, style({ 'background-image': 'url(' + this.imageNext + ')' }))
          ]);

        this.player = animationFactory.create(this.elementRef.nativeElement);
        this.player.play();

        if (this.images.length == this.images[i]) {
          this.imageNext = 'assets/images/background_wellcome_' + 1 + '.jpg';
          this.initSlider();
        } else {
          this.imageNext = 'assets/images/background_wellcome_' + (this.images[i] + 1) + '.jpg';
        }
      }, 10000 * (i + 1));
    };

  }


}
