import { Injectable } from '@angular/core';

import { User } from './user.model';
import { HttpService } from '../../core/http.service';
import { Observable } from 'rxjs/Observable';
import { MatSnackBar } from '@angular/material';
import { Page } from './page.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class UserService {

    static END_POINT = '/users';
    static USERNAME = '/username';
    static USEREMAIL = '/email';
    static GET = "/get";

    constructor(private httpService: HttpService, public snackBar: MatSnackBar) {

    }

    readCurrentUser(): Observable<User> {
        return this.readUserByEmail(this.httpService.getUseremail());
    }

    readUserByEmail(email: string): Observable<User> {
        email = btoa(email);
        return this.httpService.authToken().get(UserService.END_POINT + UserService.USEREMAIL + '/' + email);
    }

    readUserById(id: string): Observable<User> {
        return this.httpService.authToken().get(UserService.END_POINT + '/' + id);
    }

    createObservable(user: User): Observable<User> {
        return this.httpService.authToken().post(UserService.END_POINT, user).map(
            data => {
                this.successful('Usuario creado correctamente');
                return data;
            }
        );
    }


    rememberPassWord(email: string): any {
        return this.httpService.post(UserService.END_POINT + '/resetpass/email/' + email).map(
            data => {
                this.successful('El email se ha enviado con exito');
            },
            error => {
                this.successful('El email no corresponde a ningún usuario');
            }
        );
    }

    resetPassword(resetPassCode: string, resetPassEmail: string, npass: string): any {
        return this.httpService.post(UserService.END_POINT + '/resetpass/setnewpass?code=' + resetPassCode + '&useremail=' + resetPassEmail + '&npass=' + npass).map(
            data => {
                this.successful('Contraseña cambiada correctamente');
                return data;
            },
            error => {
                
            }
        );
    }

    createRegistered(user: User): Observable<User> {
        return this.httpService.post(UserService.END_POINT, user).map(
            data => {
                this.successful('Usuario registrado correctamente');
                return data;
            },
            error => {

            }
        );
    }

    delete(id: string): any {
        return this.httpService.authToken().delete(UserService.END_POINT + '/' + id).map(
            data => {
                this.successful('Usuario borrado correctamente');
                return data;
            }
        );
    }

    putObservable(user: User): Observable<boolean> {
        return this.httpService.authToken().put(UserService.END_POINT + '/' + user.id, user).map(
            data => {
                this.successful('Usuario actualizado correctamente');
                return data;
            }
        );
    }

    readAll(page: number, size: number): Observable<Page> {
        return this.httpService.authToken().get(UserService.END_POINT + '/' + UserService.GET + '?page=' + page + '&size=' + size);
    }

    private successful(message: string) {
        this.snackBar.open(message, 'OK', {
            duration: 6000
        });
    }

}
