import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MatDialog } from '@angular/material';
import { TokensService } from '../core/tokens.service';
import { CollectionsComponent } from './collections/collections.component';
import { UserService } from './shared/user.service';
import { User } from './shared/user.model';
import { BookListComponent } from './admin/bookList.component';
import { CollectionListComponent } from './admin/collectionList.component';
import { UserListComponent } from './admin/userList.component';

@Component({
  styleUrls: [`home.component.css`],
  templateUrl: `home.component.html`
})
export class HomeComponent implements OnInit ,AfterViewInit {
  ngAfterViewInit(): void {
    this.isAdminLogged();
  }
  static URL = 'home';
  user: User;
  isAdmin: boolean;
  subscription: Subscription;

  constructor(private dialog: MatDialog, private tokensService: TokensService,
    private router: Router, private userService: UserService) {
    this.user = {
      id: undefined,
      name: undefined,
      username: null,
      email: null,
      password:null,
      roles:null
    }
    this.collections();
    this.isAdmin = false;
  }
  ngOnInit(): void {
    this.userService.readCurrentUser().subscribe(
      user => {
        this.user = user;
      }
    );

    this.isAdminLogged();
  }
  private isAdminLogged(): void {
    this.tokensService.isAdmin().subscribe(
      result => {
        if (result) {
          this.isAdmin = true;
          this.AdminBookList();
        }
      }
    );

  }
  logout() {
    this.tokensService.logout();
  }


  collections() {
    this.router.navigate([HomeComponent.URL, CollectionsComponent.URL]);
  }


  home() {

  }

  userList() {
    this.router.navigate([HomeComponent.URL, UserListComponent.URL]);
  }

  //Admin menu//

  AdminBookList() {
    this.router.navigate([HomeComponent.URL, BookListComponent.URL]);
  }

  AdminCollectionsList() {
    this.router.navigate([HomeComponent.URL, CollectionListComponent.URL]);
  }

}
