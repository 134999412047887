import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WelcomeComponent } from './welcome/welcome.component';
import { HomeComponent } from './home/home.component';
import { CollectionComponent } from './home/collections/collection.component';
import { BookComponent } from './home/collections/book.component';
import { CollectionsComponent } from './home/collections/collections.component';
import { AddBookStatusDialogComponent } from './home/collections/addBookStatus-dialog.component';
import { BookListComponent } from './home/admin/bookList.component';
import { EditCreateBookComponent } from './home/admin/edit-createBook.component';
import { CollectionListComponent } from './home/admin/collectionList.component';
import { EditCreateCollectionComponent } from './home/admin/edit-createCollection.component';
import { CancelYesDialogComponent } from './core/cancel-yes-dialog.component';
import { UtilsComponent } from './core/utils.component';
import { UserListComponent } from './home/admin/userList.component';
import { EditCreateUserComponent } from './home/admin/edit-createUser.component';
import { TermsDialogComponent } from './home/shared/terms-dialog.component';
import { RegisterUserComponent } from './home/users/registerUser.component';
import { LoginUserComponent } from './home/users/loginUser.component';
import { ResetPasswordUserComponent } from './home/users/resetPasswordUser.component';
import { FilterDataComponent } from './home/admin/filter-data.component';
import { ChangeSetPasswordDialogComponent } from './home/admin/change-set-password-dialog.component';
import { FrontFilterDataComponent } from './home/collections/front-filter-data.component';
import { FrontFilterDataResults } from './home/collections/front-filter-data-results.component';
import { HelpAddBookDialogComponent } from './home/collections/help-addBook-dialog-component';



const appRoutes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: WelcomeComponent.URL },
    { path: WelcomeComponent.URL, component: WelcomeComponent },
    {
        path: HomeComponent.URL, component: HomeComponent,
        children: [
            { path: CollectionsComponent.URL, component: CollectionsComponent },
            { path: CollectionComponent.URL, component: CollectionComponent },
            { path: BookComponent.URL, component: BookComponent },
            { path: BookListComponent.URL, component: BookListComponent },
            { path: EditCreateBookComponent.URL, component: EditCreateBookComponent },
            { path: EditCreateBookComponent.NEW, component: EditCreateBookComponent },
            { path: CollectionListComponent.URL, component: CollectionListComponent },
            { path: EditCreateCollectionComponent.URL, component: EditCreateCollectionComponent },
            { path: UserListComponent.URL, component: UserListComponent },
            { path: EditCreateUserComponent.URL, component: EditCreateUserComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
    static COMPONENTS = [
        CollectionsComponent,
        CollectionComponent,
        BookComponent,
        HomeComponent,
        WelcomeComponent,
        BookListComponent,
        CollectionListComponent,
        EditCreateBookComponent,
        EditCreateCollectionComponent,
        EditCreateUserComponent,
        UserListComponent
    ];

    static COMPONENT_FACTORY = [
        AddBookStatusDialogComponent,
        CancelYesDialogComponent,
        TermsDialogComponent,
        UtilsComponent,
        RegisterUserComponent,
        LoginUserComponent,
        ResetPasswordUserComponent,
        FilterDataComponent,
        ChangeSetPasswordDialogComponent,
        FrontFilterDataComponent,
        FrontFilterDataResults,
        HelpAddBookDialogComponent
    ];
}