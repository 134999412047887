import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { HttpService } from './http.service';

import { Role } from './role.model';
import { Router } from '@angular/router';
import { Token } from './token.model';


@Injectable()
export class TokensService {

    static END_POINT = '/tokens';
    static AUTHENTICATED = '/authenticated';

    constructor(private httpService: HttpService, private router: Router) {

    }

    login(email: string, password: string, remember: boolean): Observable<any> {
        return this.httpService.login(email, password, remember, TokensService.END_POINT);
    }

    isLogged(): boolean {
        if (this.httpService.isLogged()) {
            return true;
        }
        return false;
    }

    goHome(): any {
        this.router.navigate(['home']);
    }

    goWelcome() {
        this.router.navigate(['welcome']);
    }

    logout(): void {
        this.httpService.logout();
    }

    isAdmin(): Observable<Boolean> {
        return Observable.create(
            observer => {
                this.getRole().subscribe(
                    data => {
                        if (data.includes(Role.ADMIN)) {
                            observer.next(true);
                            observer.complete();
                        } else {
                            observer.next(false);
                            observer.complete();
                        }
                    }
                );
            }
        );

    }

    isManager(): boolean {
        if (this.httpService.getRoles() !== undefined) {
            return this.httpService.getRoles().includes(Role.MANAGER);
        } else {
            return false;
        }
    }

    getRole(): Observable<Array<Role>> {
        if (this.httpService.getRoles() !== undefined) {
            return Observable.create(
                observer => {
                    observer.next(this.httpService.getRoles());
                    observer.complete();
                }
            );
        } else {
            return this.httpService.getCurrentRole(TokensService.END_POINT);
        }
    }

    getTempToken(): Token {
        return this.httpService.getTempToken();
    }


}
