import { Injectable } from "@angular/core";
import { HttpService } from "../../core/http.service";
import { Observable } from "rxjs/Observable";
import { Collection } from "./collection.model";
import { Page } from "./page.model";
import { MatSnackBar } from "@angular/material";
import { BookService } from "./book.service";

@Injectable()
export class CollectionsService {
    static END_POINT = "/collections";
    static USER_COLLECTIONS = "/userCollections";
    static GET = "/get";

    constructor(private httpService: HttpService, public snackBar: MatSnackBar, private bookService: BookService) {

    }

    createCollection(collection: Collection): Observable<Collection> {
        return this.httpService.authToken().post(CollectionsService.END_POINT, collection).map(
            data => {
                this.successful();
                return data;
            }
        );

    }

    readCollection(id: string): Observable<Collection> {
        return this.httpService.authToken().get(CollectionsService.END_POINT + '/' + id);
    }

    readCollectionsAll(): Observable<Collection[]> {
        return this.httpService.authToken().get(CollectionsService.END_POINT + CollectionsService.GET);
    }
    
    readCollectionsAllPaginate(page: number, size: number, filter: String = null): Observable<Page> {
        return this.httpService.authToken().get(CollectionsService.END_POINT + CollectionsService.GET + '?page=' + page + '&size=' + size + '&filter=' + filter);
    }

    updateCollection(id: string, collection: Collection): Observable<Collection> {
        return this.httpService.authToken().put(CollectionsService.END_POINT + '/' + id, collection).map(
            data => {
                this.successful();
                return data;
            }
        );
    }

    deleteCollection(id: string): Observable<any> {
        return this.httpService.authToken().delete(CollectionsService.END_POINT + '/' + id);
    }

    readUserCollections(userId: string, page: number, size: number, filter:string): Observable<Page> {
        return this.httpService.authToken().get(CollectionsService.END_POINT + CollectionsService.GET + CollectionsService.USER_COLLECTIONS + '/' + userId + '?page=' + page + '&size=' + size + '&filter=' + filter);
    }

    haveBooks(id: string): Observable<boolean> {
        return Observable.create(
            observer =>{
                this.bookService.getBooksByCollection(id, 0, 100).subscribe(
                    result => {
                        
                        if (result.totalElements === 0) {
                            observer.next(false);
                            observer.complete();
                        }else{
                            observer.next(true);
                            observer.complete();
                        }
                    }
                );
            }
        );
    }

    private successful() {
        this.snackBar.open('Successful', '', {
            duration: 2000
        });
    }

}