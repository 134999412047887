import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog, MatDatepickerInputEvent } from "@angular/material";
import { TermsDialogComponent } from "../shared/terms-dialog.component";
import { FormBuilder, Validators, FormControl, FormGroup } from "@angular/forms";
import { UserService } from '../shared/user.service';
import { User } from '../shared/user.model';
import { Role } from '../../core/role.model';
import { PasswordValidator, ParentErrorStateMatcher } from '../shared/validators/password.validator';
import { UtilsComponent } from '../../core/utils.component';
import 'rxjs/add/observable/throw';
import { UserValidatorMessages } from '../shared/validators/userValidatorsMessages';
@Component({
    selector: `register-user-component`,
    templateUrl: `registerUser.component.html`,
    styleUrls: [`registerUser.component.css`]
})
export class RegisterUserComponent implements OnInit {

    termsacept: boolean;

    @Output()
    registerOutput: EventEmitter<boolean> = new EventEmitter<boolean>();

    register: boolean;

    loading: boolean;
    registerForm: FormGroup;
    parentErrorStateMatcher = new ParentErrorStateMatcher();
    userValidatorMessages = new UserValidatorMessages();
    matching_passwords_group: FormGroup;
    matching_email_group: FormGroup;
    constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private userService: UserService, private utils: UtilsComponent) {

    }

    ngOnInit(): void {
        this.prepareForm();
    }

    prepareForm(): any {
        this.matching_passwords_group = new FormGroup({
            password: new FormControl('', Validators.compose([
                Validators.minLength(5),
                Validators.required
                // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
            ])),
            confirm_password: new FormControl('', Validators.required)
        }, (formGroup: FormGroup) => {
            return PasswordValidator.areEqual(formGroup);
        });

        this.matching_email_group = new FormGroup({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),
            confirm_email: new FormControl('', Validators.required)
        }, (formGroup: FormGroup) => {
            return PasswordValidator.areEqual(formGroup);
        });

        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            lastname: ['', Validators.required],
            postalcode: ['', Validators.required],
            birthdate: ['', Validators.required],
            matching_emails: this.matching_email_group,
            matching_passwords: this.matching_passwords_group,
            termsaccept: new FormControl(false, Validators.compose([
                Validators.required,
                Validators.pattern('true')
            ]))
        });

    }


    registerUser(dataForm: FormBuilder) {
        let user: User;
        let role: Role[] = [
            Role.REGISTERED
        ];

        if (dataForm) {
            let uname = this.utils.transform(dataForm['matching_emails']['email']);
            user = {
                id: null,
                birthdate: dataForm['birthdate'],
                email: dataForm['matching_emails']['email'],
                lastname: dataForm['lastname'],
                name: dataForm['name'],
                postalcode: dataForm['postalcode'],
                password: dataForm['matching_passwords'].password,
                roles: role,
                username: uname
            }

            this.userService.createRegistered(user).subscribe(
                data => {
                    this.isRegister();
                }, error => {
                    if (error.exception == "UserFieldAlreadyExistException") {
                        this.registerForm.controls['matching_emails'].setErrors({ notUnique: true });
                        this.emtyPasswordValues();
                    }
                }
            );
        }

    }
    emtyPasswordValues(): any {
        this.registerForm.controls['matching_passwords'].markAsPristine();
        /*Fix Bug when send the form and an error triggered*/
        /**
         * Remove class invalid form field from confirm password input
         */
        document.querySelectorAll('.confirm_password').forEach(item => {
            item.classList.remove('mat-form-field-invalid');
        });
    }

    isRegister(): any {
        this.registerOutput.emit(false);
    }

    terms() {
        let dialogRef = this.dialog.open(TermsDialogComponent);
        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                    this.registerForm.controls['termsaccept'].setValue(true);
                } else {
                    this.registerForm.controls['termsaccept'].setValue(false);
                }
            });
    }

}