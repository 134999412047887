import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import 'hammerjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TokensService } from './core/tokens.service';
import { UserService } from './home/shared/user.service';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  screenHeight: any;
  screenWidth: any;
  constructor(public tokensService: TokensService, private activatedRute: ActivatedRoute, private elem: ElementRef) {
    this.onResize();
  }
  result: boolean;
  ngOnInit(): void {

    if (this.tokensService.isLogged()) {
      this.tokensService.goHome();
    } else if (!this.resetPass()) {
      this.tokensService.goWelcome();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    (document.querySelector('html') as HTMLElement).style.height = this.screenHeight + "px";
  }
  ngAfterViewInit(): void {
    this.resetPass();
  }
  resetPass(): boolean {
    this.result = false;
    this.activatedRute.queryParams.subscribe(
      data => {
        if (data) {
          this.result = true;
        }
      }
    );

    return this.result;
  }


}
