import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../shared/user.model";
import { UserService } from "../shared/user.service";
import { FirebaseUploadService } from "../shared/firebaseupload.service";
import { UtilsComponent } from "../../core/utils.component";
import { Ng2ImgMaxService } from "ng2-img-max";
import { FormControl, FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Role } from "../../core/role.model";
import { MatDatepickerInputEvent, DateAdapter, MatDialog } from "@angular/material";
import { ParentErrorStateMatcher, PasswordValidator } from '../shared/validators/password.validator';
import { UserValidatorMessages } from '../shared/validators/userValidatorsMessages';
import { ChangeSetPasswordDialogComponent } from './change-set-password-dialog.component';
@Component({
    templateUrl: `edit-createuser.component.html`,
    styleUrls: [`edit-createuser.component.css`]
})
export class EditCreateUserComponent implements OnInit {
    static URL = 'userDetails/:id';
    static NEW = 'userDetails/new';
    id: string;
    user: User;
    birthdate = new FormControl(new Date());
    itemInLoading: boolean;
    dropzoneActive: boolean = false;
    roles = new FormControl();
    croles = Role;
    re_newpassword: string;
    newpassword: string;
    controlNewpassword = new FormControl();
    canChangePassword: boolean;
    userDetailsForm: FormGroup;
    userPassForm: any;
    parentErrorStateMatcher = new ParentErrorStateMatcher();
    userValidatorMessages = new UserValidatorMessages();
    matching_passwords_group: FormGroup;
    constructor(private matDialog: MatDialog, private utils: UtilsComponent, private adapter: DateAdapter<any>, private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private utilsComponent: UtilsComponent) {
        adapter.setLocale('es');
        this.id = activatedRoute.snapshot.params['id'];
        this.user = {
            id: null,
            username: null,
            lastname: null,
            postalcode: null,
            birthdate: null,
            name: null,
            email: null,
            roles: null,
            password: null
        }
        this.newpassword = null;
        this.re_newpassword = null;

    }

    ngOnInit(): void {
        if (this.id != 'new') {
            this.SyncData();
            this.canChangePassword = false;
        } else {
            this.id = null;
            this.canChangePassword = true;
        }
        this.prepareForm();
    }

    prepareForm(): any {
        this.userDetailsForm = this.formBuilder.group({
            name: [this.user.name, Validators.required],
            active: [''],
            lastname: [''],
            postalcode: [''],
            username: [''],
            roles: [''],
            email: [this.user.email, Validators.email],
            password: ['null'],
            birthdate: ['']
        });
        if (!this.id) {
            this.userDetailsForm.get('password').setValue("");
            this.userDetailsForm.controls['password'].setValidators(Validators.required);
        }
    }


    SyncData(): any {
        this.readuser(this.id);
    }

    setChangePassword() {
        this.canChangePassword = true;
        const dialogRef = this.matDialog.open(ChangeSetPasswordDialogComponent);
        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                    this.userDetailsForm.get('password').setValue(result);
                    //this.user.password = result;
                } else {
                    this.userDetailsForm.get('password').setValue("null");
                }
            }
        );
    }

    readuser(id: string): any {
        this.userService.readUserById(id).subscribe(
            data => {
                this.user = data;
                this.userDetailsForm.get('active').setValue(this.user.active);
                this.userDetailsForm.get('name').setValue(this.user.name);
                this.userDetailsForm.get('lastname').setValue(this.user.lastname);
                this.userDetailsForm.get('postalcode').setValue(this.user.postalcode);
                this.userDetailsForm.get('email').setValue(this.user.email);
                this.userDetailsForm.get('username').setValue(this.user.username);
                this.userDetailsForm.get('roles').setValue(this.user.roles);
                this.userDetailsForm.get('birthdate').setValue(new Date(data.birthdate));
                
            }
        );
    }

    setBrithDate(event: MatDatepickerInputEvent<Date>) {
        this.user.birthdate = event.value;
    }

    setNewPassword() {
        this.user.password = this.newpassword;
        this.newpassword = null;
        this.re_newpassword = null;
    }

    setDataForm(dataForm: FormBuilder) {
        let role: Role[] = [
            dataForm['roles']
        ];
        let uname = this.utils.transform(dataForm['email']);
        this.user = {
            id: this.id,
            username: uname,
            email: dataForm['email'],
            lastname: dataForm['lastname'],
            postalcode: dataForm['postalcode'],
            birthdate: dataForm['birthdate'],
            active: dataForm['active'],
            name: dataForm['name'],
            roles: dataForm['roles'],
            password: dataForm['password']
        }
        this.save(this.id);
    }

    save(id: string) {
        if (this.id) {

            this.userService.putObservable(this.user).subscribe(
                data => {
                    this.SyncData();
                }
            );
        } else {

            this.userService.createObservable(this.user).subscribe(
                data => {
                    this.id = data.id;
                    this.SyncData();
                }
            );
        }
    }

    close() {
        this.router.navigate(['home/userList']);
    }

    saveAndClose(id: string) {
        this.save(id);
        this.close();
    }

}